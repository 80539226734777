var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "container", staticClass: "container" }, [
    _vm.loading
      ? _c("div", { staticClass: "loader" }, [_vm._v("\n    Loading...\n  ")])
      : _c("div", { ref: "containerInner", staticClass: "container-inner" }, [
          _c("header", { staticClass: "header-section" }),
          _vm._v(" "),
          _c("section", { staticClass: "container-section" }, [
            _c("div", { staticClass: "center" }, [
              _c("h1", { staticClass: "slogan-block" }, [
                _vm._v(
                  "\n          Find out Renowned Online Local Chat Rooms\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "reg-form-block" }, [
                _c(
                  "div",
                  {
                    staticClass: "reg-form",
                    attrs: { "data-current-step": _vm.stepNumber },
                  },
                  [
                    _c("div", {
                      staticClass: "register-hidden-fields-wrapper",
                    }),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        attrs: {
                          id: "reg-form",
                          name: "UserForm",
                          action: "/user/register",
                          autocomplete: "off",
                        },
                      },
                      [
                        _c("div", { staticClass: "form-step-block" }, [
                          _c("div", { staticClass: "form-step-wrapper" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.stepNumber === 1,
                                    expression: "stepNumber === 1",
                                  },
                                ],
                                staticClass: "form-step-item active",
                              },
                              [
                                _vm._m(0),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-item",
                                    attrs: { id: "orientation-item" },
                                  },
                                  [
                                    _vm._m(1),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-field-block" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-select",
                                            on: { change: _vm.selectValue },
                                          },
                                          [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.selectedOrientation,
                                                    expression:
                                                      "selectedOrientation",
                                                  },
                                                ],
                                                attrs: {
                                                  id: "orientation-field",
                                                  name: "orientation",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.selectedOrientation =
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      value: "mw",
                                                      selected: "selected",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                              a man looking for a woman\n                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "wm" } },
                                                  [
                                                    _vm._v(
                                                      "\n                              a woman looking for a man\n                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "mm" } },
                                                  [
                                                    _vm._v(
                                                      "\n                              a man looking for a man\n                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "ww" } },
                                                  [
                                                    _vm._v(
                                                      "\n                              a woman looking for a woman\n                            "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "select-value" },
                                              [
                                                _vm._v(
                                                  "\n                            a man looking for a woman\n                          "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.stepNumber === 2,
                                    expression: "stepNumber === 2",
                                  },
                                ],
                                staticClass: "form-step-item active",
                              },
                              [
                                _vm._m(2),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-item is-focused",
                                    attrs: { id: "age-item" },
                                  },
                                  [
                                    _vm._m(3),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-field-block" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-select",
                                            on: { change: _vm.selectValue },
                                          },
                                          [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.selectedAge,
                                                    expression: "selectedAge",
                                                  },
                                                ],
                                                attrs: {
                                                  id: "age-field",
                                                  name: "age",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.selectedAge = $event
                                                      .target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      value: "",
                                                      selected: "selected",
                                                      disabled: "disabled",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                              Select your age\n                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  Array(61)
                                                    .fill()
                                                    .map((e, i) => i + 18),
                                                  function (index) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: index,
                                                        domProps: {
                                                          value: index,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                              " +
                                                            _vm._s(index) +
                                                            "\n                            "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "select-value" },
                                              [
                                                _vm._v(
                                                  "\n                            Select your age\n                          "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(4),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.stepNumber === 3,
                                    expression: "stepNumber === 3",
                                  },
                                ],
                                staticClass: "form-step-item active",
                              },
                              [
                                _vm._m(5),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-item",
                                    attrs: { id: "location-item" },
                                  },
                                  [
                                    _vm._m(6),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-field-block" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-input" },
                                          [
                                            _c("input", {
                                              attrs: {
                                                type: "text",
                                                name: "location",
                                                autocomplete: "off",
                                                placeholder: "London",
                                              },
                                              domProps: { value: _vm.location },
                                              on: {
                                                input: function ($event) {
                                                  _vm.location =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._m(7),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.stepNumber === 4,
                                    expression: "stepNumber === 4",
                                  },
                                ],
                                staticClass: "form-step-item active",
                              },
                              [
                                _vm._m(8),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-item",
                                    attrs: { id: "email-item" },
                                  },
                                  [
                                    _vm._m(9),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-field-block" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-input" },
                                          [
                                            _c("input", {
                                              attrs: {
                                                type: "email",
                                                name: "email",
                                                autocomplete: "off",
                                                placeholder:
                                                  "Your email address",
                                              },
                                              domProps: {
                                                value: _vm.userEmail,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  _vm.userEmail =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._m(10),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "step-btn prev-btn",
                              on: { click: _vm.prevStep },
                            },
                            [_c("span", [_vm._v("prev step")])]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "step-btn next-btn",
                              on: { click: _vm.nextStep },
                            },
                            [_c("span", [_vm._v("Next")])]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "step-btn submit-btn",
                              attrs: { id: "submit-btn" },
                              on: { click: _vm.nextStep },
                            },
                            [_c("span", [_vm._v("Start NOW!")])]
                          ),
                          _vm._v(" "),
                          _vm._m(11),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "pagination-block" }, [
                      _c("div", { staticClass: "pagination-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "pagination-item item-1 active",
                            class: { current: _vm.stepNumber === 1 },
                          },
                          [_c("span", [_vm._v("1")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "pagination-item item-2",
                            class: {
                              active: _vm.stepNumber > 1,
                              current: _vm.stepNumber === 2,
                            },
                          },
                          [_c("span", [_vm._v("2")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "pagination-item item-3",
                            class: {
                              active: _vm.stepNumber > 2,
                              current: _vm.stepNumber === 3,
                            },
                          },
                          [_c("span", [_vm._v("3")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "pagination-item item-4",
                            class: { current: _vm.stepNumber === 4 },
                          },
                          [_c("span", [_vm._v("4")])]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._m(12),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
    _vm._v(" "),
    _vm.showError
      ? _c("div", { staticClass: "err-message-wrapper js-err" }, [
          _c("div", { staticClass: "err-message" }, [
            _c("i", { staticClass: "close", on: { click: _vm.hideErr } }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.errMessage))]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.cookiePopup,
            expression: "cookiePopup",
          },
        ],
        staticClass: "cookie-popup-wrapper",
      },
      [
        _c("div", { staticClass: "cookie-popup" }, [
          _c("span", { staticClass: "icon cookie-close-popup" }),
          _vm._v(" "),
          _vm._m(13),
          _vm._v(" "),
          _c("div", { staticClass: "cookie-btn-block" }, [
            _c(
              "div",
              {
                staticClass: "cookie-accept-btn",
                on: {
                  click: function ($event) {
                    _vm.cookiePopup = false
                  },
                },
              },
              [_vm._v("\n          Accept\n        ")]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-label-block label-outer" }, [
      _c("label", { staticClass: "form-label" }, [_vm._v(" I am ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-label-block" }, [
      _c("label", { staticClass: "form-label" }, [_vm._v("I am:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-label-block label-outer" }, [
      _c("label", { staticClass: "form-label" }, [_vm._v(" My age is ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-label-block" }, [
      _c("label", { staticClass: "form-label" }, [_vm._v("My age:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-error-block" }, [
      _c("div", { staticClass: "form-error-item" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-label-block label-outer" }, [
      _c("label", { staticClass: "form-label" }, [
        _vm._v(" My location will be "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-label-block" }, [
      _c("label", { staticClass: "form-label" }, [_vm._v("My location:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-error-block" }, [
      _c("div", { staticClass: "form-error-item" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-label-block label-outer" }, [
      _c("label", { staticClass: "form-label" }, [
        _vm._v(" My email address is "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-label-block" }, [
      _c("label", { staticClass: "form-label" }, [_vm._v("My email:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-error-block" }, [
      _c("div", { staticClass: "form-error-item" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-terms" }, [
      _vm._v(
        "\n                  By clicking the submit button above you expressly consent to our "
      ),
      _c(
        "a",
        {
          staticClass: "policy-link",
          attrs: { rel: "nofollow", href: "/assets/legal/privacy.html" },
        },
        [_vm._v("Privacy policy")]
      ),
      _vm._v(
        " including processing of personal data and use of profiling to find you matches and you agree to our "
      ),
      _c(
        "a",
        {
          staticClass: "terms-link",
          attrs: { rel: "nofollow", href: "/assets/legal/tc.html" },
        },
        [_vm._v("Terms of use")]
      ),
      _vm._v(", and to receive newsletters, account updates, offers sent by "),
      _c("span", { staticClass: "siteName" }, [_vm._v("Filerypoint.com")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "step-counter-block" }, [
      _c("span", { staticClass: "step-counter-current" }, [_vm._v("1")]),
      _vm._v(" "),
      _c("span", { staticClass: "step-counter-separator" }, [_vm._v("|")]),
      _vm._v(" "),
      _c("span", { staticClass: "step-counter-total" }, [_vm._v("4")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", [
        _vm._v(
          "This site uses cookies to improve your browsing experience. By using this site, you accept our "
        ),
        _c(
          "a",
          {
            staticClass: "cookie-policy-link",
            attrs: {
              rel: "nofollow",
              href: "/assets/legal/privacy.html",
              onclick:
                "return !window.open(this.href,'','toolbar=no,directories=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=700,height=500')",
            },
          },
          [_vm._v("Privacy Policy")]
        ),
        _vm._v("."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }